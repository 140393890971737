@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary-blue: #2a4d85;
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;

    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --ring: 217.9 10.6% 64.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;

    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;

    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;

    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;

    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;

    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;

    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;

    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 215 27.9% 16.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.capture-shadow {
  box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.4);
}

.frame-layout {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 2000px !important;
  pointer-events: none !important;
  position: absolute !important;
  pointer-events: none !important;
}
.center-layout {
  transform: translate3d(0%, 50%, 0px) !important;
}
.selfie-layout {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 2000px !important;
  position: absolute !important;
  pointer-events: none !important;
  border-radius: 50% !important;
  transform: translate3d(0%, 30%, 0px) !important;
}

* {
  /* iOS safari shows black shadow on tab. It's better to disable it */
  -webkit-tap-highlight-color: transparent;
}
